import moment from 'moment';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseResponse,
  ClosedOpportunityDownload,
  Opportunity, OpportunityCurrentCardResponse, OpportunityDownload,
  OpportunityFilterRequest, OpportunityLostReasonType, OpportunityRequest, PagedResponse, Response
} from '@core/api';
import { environment } from '@env/environment';
import { AuthService } from '@core/auth/auth.service';
import { openFileUrl } from 'src/app/shared/utils/open-file-url';

@Injectable({
  providedIn: 'root'
})
export class OpportunityService {

  constructor(private http: HttpClient) { }

  get(uuid: string): Observable<Response<Opportunity>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Opportunity>>(environment.apiUrl + '/api/Opportunity/Get', JSON.stringify(uuid), { headers }).pipe(
      tap((response) => {
        response.data = this.opportunityTransformer(response.data);

        return response;
      })
    );
  }

  save(request: OpportunityRequest) {
    return this.http.post<Response<Opportunity>>(environment.apiUrl + '/api/Opportunity/Insert', request)
      .pipe(
        switchMap(response => {
          if (response.success) {
            return this.get(response.data.opportunityId);
          }

          return of(response);
        })
      );
  }

  update(request: OpportunityRequest) {
    return this.http.post<Response<Opportunity>>(environment.apiUrl + '/api/Opportunity/Update', request).pipe(
      switchMap(response => {
        if (response.success) {
          return this.get(response.data.opportunityId);
        }

        return of(response);
      })
    );
  }

  delete(uuid: string) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/Opportunity/Delete', JSON.stringify(uuid), { headers });
  }

  search(request: OpportunityFilterRequest) {
    return this.http.post<PagedResponse<Opportunity>>(environment.apiUrl + '/api/Opportunity/Search', request).pipe(
      tap(response => response.data.results.forEach(opportunity => this.opportunityTransformer(opportunity)))
    );
  }

  exportExcel(request: OpportunityFilterRequest) {
    return this.http.post<Response<OpportunityDownload[]>>(environment.apiUrl + '/api/Opportunity/Download', request.filter);
  }

  exportClosedOpportunityDownload(request: OpportunityFilterRequest) {
    return this.http.post<Response<ClosedOpportunityDownload[]>>(environment.apiUrl + '/api/Opportunity/ClosedOpportunityDownload', request.filter);
  }

  backToDraft(uuid: string): Observable<Response<Opportunity>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Opportunity>>(environment.apiUrl + '/api/Opportunity/BackToDraft', JSON.stringify(uuid), { headers }).pipe(
      switchMap(response => {
        if (response.success) {
          return this.get(response.data.opportunityId);
        }

        return of(response);
      })
    );
  }

  rejectContractIdentification(uuid: string): Observable<Response<Opportunity>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Opportunity>>
      (environment.apiUrl + '/api/Opportunity/RejectContractIdentification', JSON.stringify(uuid), { headers })
      .pipe(
        switchMap(response => {
          if (response.success) {
            return this.get(response.data.opportunityId);
          }

          return of(response);
        })
      );
  }

  opportunityTransformer(opportunity: Opportunity): Opportunity {
    ['createdOn', 'statusLastUpdate'].forEach((key) => {
      const date = moment(opportunity[key]);
      opportunity[key] = date.isValid() ? date : null;
    });

    return opportunity;
  }

  download(opportunity: Opportunity, type: string = 'preview', fileWindow?: Window, opportunityParameterId?: string): Promise<boolean> {
    return openFileUrl(
      environment.apiUrl + `/api/Opportunity/ShowPdf?id=${opportunity.opportunityId}&opportunityParameterId=${opportunityParameterId}`,
      { Authorization: 'Bearer ' + AuthService.getToken() },
      type,
      opportunity.code,
      fileWindow
    );
  }

  getLostReasonType(): Observable<Response<OpportunityLostReasonType[]>> {
    return this.http.post<Response<OpportunityLostReasonType[]>>(environment.apiUrl + '/api/OpportunityLostReasonType/List', {});
  }

  updateCustomList(request, params?: HttpParams | { [param: string]: string | string[] }) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post(environment.apiUrl + '/api/Opportunity/UpdateCustomList', request, { headers, params });
  }

  getCurrentCardForm(uuid: string): Observable<Response<OpportunityCurrentCardResponse>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<OpportunityCurrentCardResponse>>(environment.apiUrl + '/api/Opportunity/GetCurrentCardForm',
      JSON.stringify(uuid), { headers });
  }
}
